import React, { useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid2 from "@mui/material/Unstable_Grid2";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SimpleScoreInput from "../../components/SimpleScoreInput/SimpleScoreInput";
import PageLoader from "../../components/PageLoader/PageLoader";
import useCompetition from "../../hooks/competition";
import useScore from "../../hooks/score";

function InterclubsPage() {
  const competitionCode = useParams()["competitionCode"] ?? "";
  const [searchParams] = useSearchParams();
  const clubCode = searchParams.get("code") ?? "";

  useEffect(
    function updatePageTitle() {
      document.title = competitionCode + " - Interclubs - Saisie des stats - Champ'S";
    },
    [competitionCode],
  );

  const {
    competition,
    errorMessage,
    isLoading: getCompetitionIsLoading,
  } = useCompetition(competitionCode, clubCode);

  useEffect(
     () => {
      if (errorMessage !== "") {
        setTimeout(() => (window.location.href = "/"), 2000);
      }
    },
    [errorMessage],
  );

  const {
    isLoading: getScoreIsLoading,
    score,
    setScore,
   } = useScore(competitionCode, clubCode);

  const infos = useMemo(
    ()=>{
      let infos = [];
      if (competition) {
        if (competition?.championship?.name.trim()) {
          infos.push({key:"Championnat", value:competition.championship.name});
        }
        if (competition?.name.trim()) {
          infos.push({key:"Nom", value:competition.name});
        }
      }
      return infos;
    },
    [competition],
  );

  return (
    <>
      <HeaderBar>
        <Breadcrumbs>
          <Typography>{"Interclubs"}</Typography>
          <Typography color="text.primary">{competitionCode}</Typography>
        </Breadcrumbs>
      </HeaderBar>
      {(getCompetitionIsLoading || getScoreIsLoading || errorMessage !== "") ? (
        <PageLoader
          errorMessage={errorMessage}
          loadingMessage={"Chargement des données de la compétition."}
        />
      ) : (
        <>
          <Paper square={true}>
            {infos.length > 0 && (
              <>
                <Grid2
                  container
                  justifyContent="center"
                >
                  {infos.map(({key, value}) => (
                    <Grid2
                      key={key}
                      paddingX={3}
                      xs={12}
                      sm="auto"
                    >
                      <ListItem>
                        <ListItemText
                          secondary={key}
                          sx={{textAlign:"center"}}
                        >
                          {value}
                        </ListItemText>
                      </ListItem>
                    </Grid2>
                  ))}
                </Grid2>
              </>
            )}
          </Paper>

          <Grid2
            alignItems="center"
            container
            justifyContent="center"
            padding={2}
          >
            <SimpleScoreInput
              nameA={competition?.match_data?.team_a?.public_name}
              nameB={competition?.match_data?.team_b?.public_name}
              score={score}
              setScore={setScore}
            />
          </Grid2>
        </>
      )}
    </>
  );
}

export default InterclubsPage;
