import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import RemoveIcon from "@mui/icons-material/Remove";

function NumberInput({ addToValue /*: (number)=>void*/, disabled = false, step = 1, value /*: string | number*/ }) {
  return (
    <>
      <Box
        display="inline-block"
        sx={{
          "> .MuiButtonBase-root,.MuiBox-root": {
            minWidth: "0",
            padding: 0.5,
            textAlign: "center",
            fontWeight: "bold",
          },
        }}
      >
        {isNaN(Number(value)) ? (
          <>
            <Button
              disabled
              fullWidth
            >
              <Icon fontSize="small" />
            </Button>
            <Box>{value}</Box>
            <Button
              disabled
              fullWidth
            >
              <Icon fontSize="small" />
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={disabled}
              fullWidth
              onClick={() => addToValue(step)}
            >
              <AddIcon fontSize="small" />
            </Button>
            <Box>{value}</Box>
            <Button
              disabled={disabled}
              fullWidth
              onClick={() => addToValue(-step)}
            >
              <RemoveIcon fontSize="small" />
            </Button>
          </>
        )}
      </Box>
    </>
  );
}

export default NumberInput;
