import React, { useCallback } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import NumberInput from "../../components/NumberInput/NumberInput";

function SimpleScoreInput({ nameA, nameB, score, setScore }) {

  const addToScoreA = useCallback(
    value => setScore({...score, a: Math.max(0, score.a + value)}),
    [score, setScore],
  );
  const addToScoreB = useCallback(
    value => setScore({...score, b: Math.max(0, score.b + value)}),
    [score, setScore],
  );

  return (
    <Paper
      component="fieldset"
      sx={{
        maxWidth: "800px",
        width: "100%",
      }}
      variant="outlined"
    >
      <legend>{"Score"}</legend>
      <Box
        display="flex"
        wrap="nowrap"
      >
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          justifyContent="right"
          paddingX={1}
        >
          {nameA}
        </Box>
        <Box
          display="content"
          paddingY={2}
          whiteSpace="nowrap"
        >
          <NumberInput
            addToValue={addToScoreA}
            value={score.a}
          />
          <NumberInput value="-" />
          <NumberInput
            addToValue={addToScoreB}
            value={score.b}
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flex={1}
          justifyContent="left"
          paddingX={1}
        >
          {nameB}
        </Box>
      </Box>
    </Paper>
  );
}

export default SimpleScoreInput;
