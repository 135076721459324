const baseURL = process.env.REACT_APP_API_URL + "/alpha";
const xToken = process.env.REACT_APP_X_TOKEN;

const api = async (path, options) => {
  options.headers = {
    ...options.headers,
    "X-Token": xToken,
  };

  try {
    const response = await fetch(baseURL + path, options);
    if (response.ok) {
      if (response.headers.get("content-length") === "0") {
        return null;
      } else {
        return await response.json();
      }
    } else {
      throw new Error("API call response status is " + response.status);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteQuery = async (path) =>
  api(path, {
    method: "DELETE",
    mode: "cors",
  });

export const getQuery = async (path) =>
  api(path, {
    headers: {},
    method: "GET",
    mode: "cors",
  });

export const patchQuery = async (path, body = {}) =>
  api(path, {
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
    method: "PATCH",
    mode: "cors",
  });

export const postQuery = async (path, body = {}) =>
  api(path, {
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
    method: "POST",
    mode: "cors",
  });

export const putQuery = async (path, body = {}) =>
  api(path, {
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
    method: "PUT",
    mode: "cors",
  });
