import { useCallback, useEffect, useState } from "react";

import { getQuery, postQuery } from "../utils/champsAPI.util";

function useScore(competitionCode, clubCode) {
  const [isLoading, setIsLoading] = useState(true);
  const [localScore, setLocalScore] = useState({ a: 0, b: 0 });
  const [score, setScore] = useState();
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(
    () => {
      getQuery(
        `/score/parent/${competitionCode}?code=${clubCode}&timestamp=${timestamp}`
      ).then(
        (data) => {
          if (data && data?.a >= 0 && data?.b >= 0) {
            setLocalScore({ ...data });
            setScore({ ...data });
          }
        }
      ).catch(
        () => null
      ).finally(
        () => setIsLoading(false)
      );
    },
    [competitionCode, clubCode, timestamp]
  );

  useEffect(
    () => {
      if (
        (!score && !isLoading) ||
        (score && (localScore.a !== score.a || localScore.b !== score.b))
      ) {
        const now = Date.now();
        postQuery("/score", {
          code_rencontre: competitionCode,
          score: {
            a: localScore.a,
            b: localScore.b,
          },
          sport: "parent",
          timestamp: now,
        }).then(
          () => setTimestamp(now)
        ).catch(
          () => null
        );
      }
    },
    [competitionCode, isLoading, localScore, score]
  )

  return {
    isLoading,
    score: localScore,
    setScore: setLocalScore,
  }
}

export default useScore;
