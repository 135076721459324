import React, { useCallback, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";


function HeaderBar({ children }) {
  const [openDialog, setOpenDialog] = useState(false);

  const onIconClick = useCallback(() => {
    if (window.location.pathname !== "/") {
      setOpenDialog(true);
    }
  }, []);

  const handleDialogClose = useCallback((event) => {
    if (event.currentTarget.value === "yes") {
      window.location.href = "/";
    }
    setOpenDialog(false);
  }, []);

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Tooltip
            disableInteractive
            title="revenir à la page d'accueil"
          >
            <ButtonBase
              disableRipple={true}
              disabled={window.location.pathname === "/"}
              onClick={onIconClick}
              sx={{paddingRight:"24px"}}
            >
              <img alt="home logo" src="/logo.svg" />
            </ButtonBase>
          </Tooltip>
          {children}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Dialog
        onClose={handleDialogClose}
        open={openDialog}
      >
        <DialogTitle>{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{"Voulez-vous revenir à la page d'accueil ?"}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            value="no"
          >
            {"Non"}
          </Button>
          <Button
            onClick={handleDialogClose}
            value="yes"
          >
            {"Oui"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default HeaderBar;
