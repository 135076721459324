import { useEffect, useState } from "react";

import { getQuery } from "../utils/champsAPI.util";

function useCompetition(competitionCode, clubCode) {
  const [competition, setCompetition] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(
    () => {
      getQuery(`/competitions/${competitionCode}?code=${clubCode}`).then(
        (data) => {
          if (data && data.type === "INTERCLUB_PARENT") {
            setCompetition(data);
          } else {
            setErrorMessage("Les données du match sont invalides.");
          }
        }
      ).catch(
        () => setErrorMessage("Les données du match sont indisponibles.")
      ).finally(
        () => setIsLoading(false)
      );
    },
    [competitionCode, clubCode]
  );

  return {
    competition,
    errorMessage,
    isError: errorMessage !== "",
    isLoading,
  }
}

export default useCompetition;
