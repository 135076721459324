import React from "react";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Grid2 from "@mui/material/Unstable_Grid2";
import Snackbar from "@mui/material/Snackbar";

function PageLoader({ errorMessage="", loadingMessage="" }) {
  return (
    <>
      <Grid2
        alignItems="center"
        container
        height="100vh"
        justifyContent="center"
        position="absolute"
        top="0"
        width="100vw"
      >
        <Grid2
          alignItems="center"
          container
          justifyContent="center"
        >
          <CircularProgress
            disableShrink={true}
            size="100px"
          />
          <Grid2
            padding="20px"
            textAlign="center"
            width="100%"
          >
            {loadingMessage}
          </Grid2>
        </Grid2>
      </Grid2>
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={errorMessage !== ""}
      >
        <Alert
          severity="error"
          variant="filled"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default PageLoader;
